import { useState } from "react";
import api from "../../../api/api";
import { getCurrentUser } from "../../../components/services/auth/authService";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { Trash2 } from "react-feather";

const SchoolAttended = () => {
  const user = getCurrentUser();
  const [loading, setLoading] = useState(false);
  const initialState = {
    id: uuidv4(),
    name: "",
    institution: "",
    reg_no: "",
    start_date: "",
    finish_date: "",
    qualifications: "",
  };
  const [content, setContent] = useState(initialState);
  const [schoolAttended, setSchoolAttended] = useState([]);

  const handleChange = (e) => {
    setContent((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const { name, institution, reg_no, start_date, finish_date, qualifications } = content;

  const handleSelect = () => {
    if (!name || !institution || !reg_no || !start_date || !finish_date || !qualifications) return;
    const isPresent = schoolAttended.find((p) => p.company === institution);
    if (isPresent) return toast.info(institution + " " + "already selected");
    setSchoolAttended([...schoolAttended, content]);
    setContent(initialState);
  };

  const handleDelete = (id) => {
    let newList = schoolAttended.filter((item) => item.id !== id);
    setSchoolAttended(newList);
  };

  let onSave = schoolAttended.length > 0

  const updateSchoolAttended = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let res = await api.post(
        `/admissions/applicant/school-attended/update/${user.application_id}/`,
        { schools_attended: schoolAttended }
      );
      console.log(res);
      toast.success("School attended updated");
      setLoading(false);
      setSchoolAttended([])
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <div class="row">
      <div class="col-lg-12 col-xl-9 mx-auto">
        <div class="card">
          <div class="card-body">
            <div class="">
              <form
                class="form-horizontal form-material mb-0"
              >
                <div class="form-group row">
                  <div class="col-md-4">
                    <label>Name of school</label>
                    <input
                      type="text"
                      placeholder="Enter name of institution"
                      name="name"
                      class="form-control"
                      onChange={handleChange}
                      value={name}
                      required
                    />
                  </div>
                  <div class="col-md-4">
                    <label>Institution</label>
                    <input
                      type="text"
                      placeholder="Enter institution"
                      name="institution"
                      class="form-control"
                      onChange={handleChange}
                      value={institution}
                      required
                    />
                  </div>
                  <div class="col-md-4">
                    <label>Reg. Number</label>
                    <input
                      type="text"
                      placeholder="Enter duration"
                      name="reg_no"
                      class="form-control"
                      onChange={handleChange}
                      value={reg_no}
                      required
                    />
                  </div>
                </div>
                <div className="form-group row">
                    
                  <div class="col-md-4">
                    <label>Start Date</label>
                    <input
                      type="date"
                      placeholder="Enter date"
                      name="start_date"
                      class="form-control"
                      onChange={handleChange}
                      value={start_date}
                      required
                    />
                  </div>
                  <div class="col-md-4">
                    <label>Finish Date</label>
                    <input
                      type="date"
                      placeholder="Enter date"
                      name="finish_date"
                      class="form-control"
                      onChange={handleChange}
                      value={finish_date}
                      required
                    />
                  </div>
                  <div class="col-md-4">
                    <label>Qualification</label>
                    <input
                      type="text"
                      placeholder="Enter qualification"
                      name="qualifications"
                      class="form-control"
                      onChange={handleChange}
                      value={qualifications}
                      required
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-3">
                    <button
                      type="button"
                      onClick={handleSelect}
                      className="btn btn-warning"
                      disabled={loading}
                    >
                      Select
                    </button>
                  </div>
                </div>
              </form>
            </div>
            {schoolAttended.length ? (
              <table>
                <thead>
                  <th>Name</th>
                  <th>Institution</th>
                  <th>Reg. Number</th>
                  <th>Start Date</th>
                  <th>Finish Date</th>
                  <th>Qualification</th>
                  <th></th>
                </thead>
                {schoolAttended?.map((list) => (
                  <tbody>
                    <td>{list.name}</td>
                    <td>{list.institution}</td>
                    <td>{list.reg_no}</td>
                    <td>{list.start_date}</td>
                    <td>{list.finish_date}</td>
                    <td>{list.qualifications}</td>
                    <td onClick={() => handleDelete(list.id)}>
                      <Trash2 className="waves-effect align-self-center icon-dual-pink icon-lg" />
                    </td>
                  </tbody>
                ))}
              </table>
            ) : (
              ""
            )}
          </div>
          <div>
          </div>
            <button onClick={updateSchoolAttended} className="btn btn-primary" disabled={!onSave || loading}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default SchoolAttended;
