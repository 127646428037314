import ataLogo from "../assets/images/ATA.png";
const Footer = () => {
  return (
    <footer class="footer text-center text-sm-left mt-5">
      &copy; 2023 CIWA{" "}
      <span class="text-muted d-none d-sm-inline-block float-right">
        Powered by <img style={{ height: "3rem" }} src={ataLogo}></img> by
        ATASOFTEC
      </span>
    </footer>
  );
};

export default Footer;
