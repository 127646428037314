import { useState } from "react";
import api from "../../../api/api";
import { getCurrentUser } from "../../../components/services/auth/authService";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { Trash2 } from "react-feather";

const Referees = () => {
  const user = getCurrentUser();
  const [loading, setLoading] = useState(false);
  const initialState = {
    id: uuidv4(),
    name: "",
    position:"",
    email: "",
    phone: "",
  };
  const [content, setContent] = useState(initialState);
  const [referees, setReferees] = useState([]);

  const handleChange = (e) => {
    setContent((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const { name, position, email, phone } = content;

  const handleSelect = () => {
    if (!name || !position || !email || !phone) return;
    const isPresent = referees.find((p) => p.name === name);
    if (isPresent) return toast.info(name + " " + "already selected");
    setReferees([...referees, content]);
    setContent(initialState);
  };

  const handleDelete = (id) => {
    let newList = referees.filter((item) => item.id !== id);
    setReferees(newList);
  };

  let onSave = referees.length > 0

  const updateReferees = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let res = await api.post(
        `/admissions/applicant/referee/update/${user.application_id}/`,
        { referral_data: referees }
      );
      console.log(res);
      toast.success("Work experience updated");
      setLoading(false);
      setReferees([])
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <div class="row">
      <div class="col-lg-12 col-xl-9 mx-auto">
        <div class="card">
          <div class="card-body">
            <div class="">
              <form
                class="form-horizontal form-material mb-0"
              >
                <div class="form-group row">
                  <div class="col-md-3">
                    <label>Referee name</label>
                    <input
                      type="text"
                      placeholder="Enter name of referee"
                      name="name"
                      class="form-control"
                      onChange={handleChange}
                      value={name}
                      required
                    />
                  </div>
                  <div class="col-md-3">
                    <label>Position</label>
                    <input
                      type="text"
                      placeholder="Enter position"
                      name="position"
                      class="form-control"
                      onChange={handleChange}
                      value={position}
                      required
                    />
                  </div>
                  <div class="col-md-3">
                    <label>Email</label>
                    <input
                      type="email"
                      placeholder="Enter email"
                      name="email"
                      class="form-control"
                      onChange={handleChange}
                      value={email}
                      required
                    />
                  </div>
                  <div class="col-md-3">
                    <label>Phone number</label>
                    <input
                      type="number"
                      placeholder="Enter phone number"
                      name="phone"
                      class="form-control"
                      onChange={handleChange}
                      value={phone}
                      required
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-3">
                    <button
                      type="button"
                      onClick={handleSelect}
                      className="btn btn-warning"
                      disabled={loading}
                    >
                      Select
                    </button>
                  </div>
                </div>
              </form>
            </div>
            {referees.length ? (
              <table>
                <thead>
                  <th>Name</th>
                  <th>Position</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th></th>
                </thead>
                {referees?.map((list) => (
                  <tbody>
                    <td>{list.name}</td>
                    <td>{list.position}</td>
                    <td>{list.email}</td>
                    <td>{list.phone}</td>
                    <td onClick={() => handleDelete(list.id)}>
                      <Trash2 className="waves-effect align-self-center icon-dual-pink icon-lg" />
                    </td>
                  </tbody>
                ))}
              </table>
            ) : (
              ""
            )}
          </div>
          <div>
          </div>
            <button onClick={updateReferees} className="btn btn-primary" disabled={!onSave || loading}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default Referees;
