import { async } from "q";
import React, { useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import api from "../../../api/api";
import {
  GetApplicantDetails,
  GetApplicantOlevel,
  GetPaymentStatus,
  GetUploadedDocuments,
} from "../../../api/application";
import logo from "../../../assets/images/CIWA_logo.png";
import avatar from "../../../assets/images/user.png";
import { getCurrentUser } from "../../../components/services/auth/authService";
import useDetails from "../../../hooks/useDetails";
import useProspective from "../../../hooks/useProspective";
import useProspectiveInfo from "../../../hooks/useProspectiveInfo";
import CustomModal from "../../application/components/CustomModal";

const ApprovedPreview = () => {
  const user = getCurrentUser();
  const [passport, setPassport] = useState();
  const [olevel, setOlevel] = useState();
  const [olevel2, setOlevel2] = useState();
  const [examDetails, setExamDetails] = useState();
  const [examDetails2, setExamDetails2] = useState();
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState();
  const [admissionFee, setAdmissionFee] = useState();
  const [resultFee, setResultFee] = useState();
  const [openModal, setOpenModal] = useState(false);
  const location = useLocation();

  const address = process.env.REACT_APP_STAGING_URL;

  const { data: student } = useProspective();
  const { staging: stage } = useProspectiveInfo();

  const courseDetails = student?.admin_response[0].approved_program;

  const fetchPaymentStatus = async () => {
    try {
      const { data } = await GetPaymentStatus(user.application_id);
      console.log(data, "statuss");
      setAdmissionFee(data.application_fee);
      setResultFee(data.result_checking_fee);
    } catch (error) {}
  };

  const navigate = useNavigate();

  const printPaymentReceipt = () => {
    window.print();
  };

  const componentRef = useRef();

  const pageStyle = `
  @page {
    size: 210mm 297mm;
    margin: 0rem 8rem;
  }
`;
  const printAcceptanceLetter = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Cleatance Form",
    onAfterPrint: () => console.log("Application letter printed"),
    pageStyle: pageStyle,
  });

  // const getOlevel = async () => {
  //   try {
  //     const data = await GetApplicantOlevel(user.admission_id);
  //     console.log("Olevel list", data.olevel);
  //     setOlevel(data.olevel);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getFirstSittingCourses = async () => {
    try {
      const response = api
        .get(`/admissions/applicant/olevel/${student?.id}/1`)
        .then(({ data }) => {
          setExamDetails(data);
          setOlevel(data.olevel);
          console.log(data);
        });
    } catch (error) {}
  };

  const getSecondSittingCourses = async () => {
    try {
      const response = api
        .get(`/admissions/applicant/olevel/${student?.id}/2`)
        .then(({ data }) => {
          setExamDetails2(data);
          setOlevel2(data.olevel);
        });
    } catch (error) {}
  };

  const onImageError = (e) => {
    e.target.src = avatar;
  };

  const submitApplication = async () => {
    setLoading(true);
    try {
      const { data } = await api.put(
        `/prospectives/submit/${user.admission_id}`
      );
      console.log("Submission Response", data);
      toast.success("Clearance submitted");
      setLoading(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getUploadedDoc = async () => {
    try {
      const data = await GetUploadedDocuments(user?.admission_id);
      console.log("uploaded doc", data);
      setDocuments(data.data);
    } catch (error) {}
  };

  useEffect(() => {
    getFirstSittingCourses();
    getSecondSittingCourses();
  }, [student]);

  useEffect(() => {
    getFirstSittingCourses();
    getSecondSittingCourses();
    fetchPaymentStatus();
    getUploadedDoc();
  }, []);

  if (stage === null) {
    return (
      <Navigate
        to={{
          pathname: `/admission/payment`,
          state: { from: location },
        }}
        replace
      />
    );
  }
  return (
    <>
      {student && (
        <div
          className="receipt"
          style={{ marginTop: "3rem" }}
          ref={componentRef}
        >
          <div className="contents">
            <div className="receipt-header">
              <div className="school-title">
                <img src={logo} alt="DSP" className="logo" />
                <h3 className="name">
                  Catholic Institute of West Africa (Post Graduate)
                </h3>
              </div>
              <h4 className="subheader">
                CIWA ADMISSION CLEARANCE {student?.entrySession} <br />
                <span>Prospective Student Record</span>
              </h4>
            </div>
            <div className="receipt-body">
              <div className="body-header">
                <div className="passport">
                  <img
                    src={student?.photo}
                    onError={onImageError}
                    alt="Passport"
                  />
                </div>
                <div className="user-details">
                  <div>
                    <h3 className="user-details-option">Admission Number:</h3>
                    <h3 className="user-details-info">{student?.username}</h3>
                  </div>
                  <div>
                    <h3 className="user-details-option">Surname:</h3>
                    <h3 className="user-details-info">{student?.surname}</h3>
                  </div>
                  <div>
                    <h3 className="user-details-option">Othername(s):</h3>
                    <h3 className="user-details-info">{student?.othername}</h3>
                  </div>
                </div>
                <div className="qr-code">
                  {student && <QRCode value={student?.username} size={90} />}
                </div>
              </div>
              <hr />
              <div className="body-info">
                <div>
                  <p className="user-details-option">Date of Birth:</p>
                  <p className="user-details-info">
                    {student?.dob + "/" + student?.mob + "/" + student?.yob}
                  </p>
                </div>
                <div>
                  <p className="user-details-option">Gender:</p>
                  <p className="user-details-info">{student?.gender}</p>
                </div>
                <div>
                  <p className="user-details-option">Email Address:</p>
                  <p className="user-details-info"> {student?.email}</p>
                </div>
                <div>
                  <p className="user-details-option">Nationality:</p>
                  <p className="user-details-info">{student?.country_origin}</p>
                </div>
                <div>
                  <p className="user-details-option">State/LGA:</p>
                  <p className="user-details-info">
                    {student?.state_origin}/{student?.lga_origin}
                  </p>
                </div>
                <br />
                <h4>
                  <b>Programme Details</b>
                </h4>
                <div>
                  <p className="user-details-option">Department:</p>
                  <p className="user-details-info">
                    {courseDetails?.department}
                  </p>
                </div>
                <div>
                  <p className="user-details-option">Faculty/School:</p>
                  <p className="user-details-info">
                    {courseDetails?.faculty_name}
                  </p>
                </div>
                <br />
                <h4>
                  <b>1st Sitting Results</b>
                </h4>

                <div>
                  <p className="user-details-option">Examination Name:</p>
                  <p className="user-details-info">{examDetails?.exam_name}</p>
                </div>
                <div>
                  <p className="user-details-option">
                    Examination Reg. Number:
                  </p>
                  <p className="user-details-info">
                    {examDetails?.exam_reg_no}
                  </p>
                </div>
                <div>
                  <p className="user-details-option">Examination Date:</p>
                  <p className="user-details-info">{examDetails?.exam_date}</p>
                </div>
                <div>
                  <table>
                    <th>Subject</th>
                    <th>Grade</th>
                    <th>Exam Name</th>
                    <th>Sitting</th>
                    <tbody>
                      {olevel?.map((item) => (
                        <tr key={item.id}>
                          <td>{item.subject}</td>
                          <td>{item.grade}</td>
                          <td>{item.exam_name}</td>
                          <td>{item.seating}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <br />
                {olevel2 ? (
                  <>
                    <h4>
                      <b>Second Sitting Results</b>
                    </h4>
                    <div>
                      <p className="user-details-option">Examination Name:</p>
                      <p className="user-details-info">
                        {examDetails2?.exam_name}
                      </p>
                    </div>
                    <div>
                      <p className="user-details-option">
                        Examination Reg. Number:
                      </p>
                      <p className="user-details-info">
                        {examDetails2?.exam_reg_no}
                      </p>
                    </div>
                    <div>
                      <p className="user-details-option">Examination Date:</p>
                      <p className="user-details-info">
                        {examDetails2?.exam_date}
                      </p>
                    </div>
                    <div>
                      <table>
                        <th>Subject</th>
                        <th>Grade</th>
                        <th>Exam Name</th>
                        <th>Sitting</th>
                        <tbody>
                          {olevel2?.map((item) => (
                            <tr key={item.id}>
                              <td>{item.subject}</td>
                              <td>{item.grade}</td>
                              <td>{item.exam_name}</td>
                              <td>{item.seating}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>

            <hr />
            <div className="card-body col-lg-6">
              <h6 class="mb-2">Document Uploaded</h6>
              {documents?.map((document) => (
                <div class="alert dspg-info" role="alert">
                  {document?.doc_desc}{" "}
                  <a
                    href={address + document?.doc_path}
                    class="alert-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Document
                  </a>
                </div>
              ))}
            </div>

            <div className="print-button">
              {stage === "submitted" ? (
                <button className="btn-primary" onClick={printAcceptanceLetter}>
                  Print Application
                </button>
              ) : (
                <>
                  <button className="btn-danger">Edit</button>
                  <button
                    className="btn-primary"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                  >
                    Submit Application
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      )
      <CustomModal
        submitApplication={submitApplication}
        loading={loading}
        modalTitle="Are you sure?"
        modalBody="Make sure you have provided all necessary information before proceeding. You cannot access them after submission except you contact the school's Admin."
      />
    </>
  );
};

export default ApprovedPreview;
