import { useState } from "react";
import api from "../../../api/api";
import { getCurrentUser } from "../../../components/services/auth/authService";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { Trash2 } from "react-feather";

const WorkExperience = () => {
  const user = getCurrentUser();
  const [loading, setLoading] = useState(false);
  const initialState = {
    id: uuidv4(),
    company: "",
    position: "",
    duration: "",
  };
  const [content, setContent] = useState(initialState);
  const [workExperience, setWorkExperience] = useState([]);

  const handleChange = (e) => {
    setContent((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const { company, position, duration } = content;

  const handleSelect = () => {
    if (!company || !position || !duration) return;
    const isPresent = workExperience.find((p) => p.company === company);
    if (isPresent) return toast.info(company + " " + "already selected");
    setWorkExperience([...workExperience, content]);
    setContent(initialState);
  };

  const handleDelete = (id) => {
    let newList = workExperience.filter((item) => item.id !== id);
    setWorkExperience(newList);
  };

  let onSave = workExperience.length > 0

  const updateExperience = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let res = await api.post(
        `/admissions/applicant/work-experience/update/${user.application_id}/`,
        { work_experience: workExperience }
      );
      console.log(res);
      toast.success("Work experience updated");
      setLoading(false);
      setWorkExperience([])
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <div class="row">
      <div class="col-lg-12 col-xl-9 mx-auto">
        <div class="card">
          <div class="card-body">
            <div class="">
              <form
                class="form-horizontal form-material mb-0"
              >
                <div class="form-group row">
                  <div class="col-md-4">
                    <label>Company name</label>
                    <input
                      type="text"
                      placeholder="Enter name of company"
                      name="company"
                      class="form-control"
                      onChange={handleChange}
                      value={company}
                      required
                    />
                  </div>
                  <div class="col-md-4">
                    <label>Position</label>
                    <input
                      type="text"
                      placeholder="Enter position"
                      name="position"
                      class="form-control"
                      onChange={handleChange}
                      value={position}
                      required
                    />
                  </div>
                  <div class="col-md-4">
                    <label>Duration (year e.g 2016 - Date)</label>
                    <input
                      type="text"
                      placeholder="Enter duration"
                      name="duration"
                      class="form-control"
                      onChange={handleChange}
                      value={duration}
                      required
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-3">
                    <button
                      type="button"
                      onClick={handleSelect}
                      className="btn btn-warning"
                      disabled={loading}
                    >
                      Select
                    </button>
                  </div>
                </div>
              </form>
            </div>
            {workExperience.length ? (
              <table>
                <thead>
                  <th>Company</th>
                  <th>Position</th>
                  <th>Duration</th>
                  <th></th>
                </thead>
                {workExperience?.map((list) => (
                  <tbody>
                    <td>{list.company}</td>
                    <td>{list.position}</td>
                    <td>{list.duration}</td>
                    <td onClick={() => handleDelete(list.id)}>
                      <Trash2 className="waves-effect align-self-center icon-dual-pink icon-lg" />
                    </td>
                  </tbody>
                ))}
              </table>
            ) : (
              ""
            )}
          </div>
          <div>
          </div>
            <button onClick={updateExperience} className="btn btn-primary" disabled={!onSave || loading}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default WorkExperience;
