import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PrivateAdmission from "./components/privateRouter/PrivateAdmission";
import PrivateClearanceOfficer from "./components/privateRouter/PrivateClearanceOfficer";
import AdmissionLogin from "./pages/admission/AdmissionLogin";
import AcceptanceReceipt from "./pages/admission/setup/acceptancePayment/AcceptanceReceipt";
import AcceptanceVerifyPayment from "./pages/admission/setup/acceptancePayment/AcceptanceVerifyPaymet";
import AdmissionAcceptancePayment from "./pages/admission/setup/acceptancePayment/AdmissionAcceptancePayment";
import SuccessAcceptance from "./pages/admission/setup/acceptancePayment/SuccessAcceptance";
import AdmissionBioData from "./pages/admission/setup/AdmissionBioData";
import AdmissionChangePassword from "./pages/admission/setup/AdmissionChangePassword";
import AdmissionDashboard from "./pages/admission/setup/AdmissionDashboard";
import AdmissionLetter from "./pages/admission/setup/AdmissionLetter";
import AdmissionUploadDoc from "./pages/admission/setup/AdmissionUploadDoc";
import ResultPaymentReceipt from "./pages/admission/setup/resultVerification/ResultPaymentReceipt";
import ResultVerificationFee from "./pages/admission/setup/resultVerification/ResultVerificationFee";
import SuccessPage from "./pages/admission/setup/resultVerification/SuccessPage";
import VerifyResultPayment from "./pages/admission/setup/resultVerification/VerifyResultPayment";
import Wrapper from "./pages/admission/Wrapper";
import Clearance from "./pages/clearance/Clearance";
import ClearanceList from "./pages/clearance/ClearanceList";
import ClearanceLogin from "./pages/clearance/ClearanceLogin";
import ClearanceResetPassword from "./pages/clearance/ClearanceResetPassword";
import ApplicationWrapper from "./pages/application/ApplicationWrapper";
import ApplicationBio from "./pages/application/setup/ApplicationBio";
import ApplicationRegister from "./pages/application/ApplicationRegister";
import ApplicationLogin from "./pages/application/ApplicationLogin";
import ApplicationDashboard from "./pages/application/setup/ApplicationDashboard";
import ApplicationPayment from "./pages/application/setup/ApplicationPayment";
import ApplicationPaymentVerification from "./pages/verification";
import SuccessPayment from "./components/SuccessPayment";
import EducationDetails from "./pages/application/setup/EducationDetails";
import ApplicationLandingPage from "./pages/application/ApplicationLandingPage";
import ApplicantInvoice from "./pages/application/ApplicantInvoice";
import ApplicationPreview from "./pages/application/setup/ApplicationPreview";
import ApplicationPaymentInvoice from "./pages/application/setup/ApplicationPaymentInvoice";
import AccountSuccess from "./pages/application/AccountSuccess";
import ApplicationResponse from "./pages/application/ApplicationResponse";
import ApprovedWrapper from "./pages/approvedApplication/ApprovedWrapper";
import ApprovedDashboard from "./pages/approvedApplication/setup/ApprovedDashboard";
import ApprovedAdmissionLetter from "./pages/approvedApplication/ApprovedAdmissionLetter";
import ApprovedUploadDoc from "./pages/approvedApplication/setup/ApprovedUploadDoc";
import ApprovedPayment from "./pages/approvedApplication/setup/ApprovedPayment";
import ApprovedCOC from "./pages/approvedApplication/setup/ApprovedCOC";
import ApprovedBiodata from "./pages/approvedApplication/setup/ApprovedBiodata";
import ApprovedEduDetails from "./pages/approvedApplication/setup/ApprovedEduDetails";
import ApprovedPreview from "./pages/approvedApplication/setup/ApprovedPreview";
import ApprovedPaymentInvoice from "./pages/approvedApplication/setup/ApprovedPaymentInvoice";
import ApprovedInvoice from "./pages/approvedApplication/ApprovedInvoice";
import ApplicationResetPassword from "./pages/application/ApplicationResetPassword";
import ApplicationNewPassword from "./pages/application/ApplicationNewPassword";
import Accomodation from "./pages/approvedApplication/setup/Accommodation";
import HostelInvoice from "./pages/approvedApplication/setup/HostelInvoice";
import ApplicationUploadDoc from "./pages/application/setup/ApplicationUploadDoc";

function App() {
  return (
    <div className="App">
      <ToastContainer />
        <Routes>
          {/* <Route exact path="/admission/results/verify-payment" element={<VerifyResultPayment/>}/>
          <Route exact path="/admission/results/verify-payment/success" element={<SuccessPage/>}/>
          <Route exact path="/admission/result-payment/receipt" element={<ResultPaymentReceipt/>}/>
          <Route exact path="/admission/verify-payment" element={<AcceptanceVerifyPayment/>}/>
          <Route exact path="/admission/verify-payment/success" element={<SuccessAcceptance/>}/>
          <Route exact path="/admission/acceptance-receipt" element={<AcceptanceReceipt/>}/>
          <Route exact path="/admission/login" element={<AdmissionLogin />} />
          <Route exact path="/admission" element={<PrivateAdmission />}>
            <Route exact path="/admission" element={<Wrapper />}>
              <Route exact path="admission-letter" element={<AdmissionLetter/>}/>
              <Route exact path="" element={<AdmissionDashboard/>}/>
              <Route exact path="biodata" element={<AdmissionBioData/>}/>
              <Route exact path="password" element={<AdmissionChangePassword/>}/>
              <Route exact path="acceptance" element={<AdmissionAcceptancePayment/>}/>
              <Route exact path="result-payment" element={<ResultVerificationFee/>}/>
              <Route exact path="upload" element={<AdmissionUploadDoc/>}/>
            </Route>
          </Route> */}

          {/* Clearance officer */}
          {/* <Route exact path="/staff/admissions/clearance/login" element={<ClearanceLogin/>}/>
          <Route exact path="/staff/admissions/clearance" element={<PrivateClearanceOfficer/>}>
            <Route path="" element={<ClearanceList />} />
            <Route exact path=":clearance_id" element={<Clearance />} />
            <Route
              exact
              path="reset-password"
              element={<ClearanceResetPassword />}
            />
          </Route> */}

          {/* Application */}
          <Route exact path="/" element={<ApplicationLandingPage/>}/>
          <Route exact path="/apply/register" element={<ApplicationRegister/>}/>
          <Route exact path="/apply/login" element={<ApplicationLogin/>}/>
          <Route exact path="/apply/account-created" element={<AccountSuccess/>}/>
          <Route exact path="/apply/admission-status" element={<ApplicationResponse/>}/>
          <Route exact path="/apply" element={<ApplicationWrapper/>}>
            <Route exact path="" element={<ApplicationDashboard/>}/>
            <Route exact path="biodata" element={<ApplicationBio/>}/>
            <Route exact path="payment" element={<ApplicationPayment/>}/>
            <Route exact path="payment-invoice" element={<ApplicationPaymentInvoice/>}/>
            <Route exact path="education-details" element={<EducationDetails/>}/>
            <Route exact path="upload-doc" element={<ApplicationUploadDoc/>}/>
            <Route exact path="preview" element={<ApplicationPreview/>}/>
          </Route>

          {/* Verification */}
          <Route exact path="/paystack/verify" element={<ApplicationPaymentVerification/>}/>

          {/* Success component */}
          <Route exact path="/successful" element={<SuccessPayment/>}/>
          <Route exact path="/invoice" element={<ApplicantInvoice/>}/>

          {/*Reset password*/}
          <Route exact path="/reset-password" element={<ApplicationResetPassword/>}/>
          <Route exact path="/password/reset/:uidb64/:token" element={<ApplicationNewPassword/>}/>

          {/* Approved/New admission routes. Applicant who were offered admission... This is their route  */}
          <Route exact path="/admission/admission-letter" element={<ApprovedAdmissionLetter/>}/>
          <Route exact path="/admission/invoice" element={<ApprovedInvoice/>}/>
          <Route exact path="/admission/hostel/invoice" element={<HostelInvoice/>}/>
          <Route exact path="/admission" element={<ApprovedWrapper/>}>
            <Route exact path="" element={<ApprovedDashboard/>}/>
            <Route exact path="payment" element={<ApprovedPayment/>}/>
            <Route exact path="payment-invoice" element={<ApprovedPaymentInvoice/>}/>
            <Route exact path="upload-doc" element={<ApprovedUploadDoc/>}/>
            <Route exact path="change-of-course" element={<ApprovedCOC/>}/>
            <Route exact path="biodata" element={<ApprovedBiodata/>}/>
            <Route exact path="education-details" element={<ApprovedEduDetails/>}/>
            <Route exact path="preview" element={<ApprovedPreview/>}/>
            <Route exact path="hostel" element={<Accomodation/>}/>
          </Route>
        </Routes>
    </div>
  );
}

export default App;
