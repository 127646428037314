import React, { useEffect, useState } from "react";
import { getCurrentUser } from "../../../components/services/auth/authService";
import avatar from "../../../assets/images/user.png";
import {
  GetApplicantDetails,
  GetApplicationFee,
  GetPaymentInvoice,
  GetPaymentStatus,
  GetProspectiveDetails,
} from "../../../api/application";
import SmallCard from "../../application/components/SmallCard";
import ApplicationCheckCard from "../../application/components/ApplicationCheckCard";
import { Info } from "react-feather";
import { useNavigate, useLocation } from "react-router-dom";
import useDetails from "../../../hooks/useDetails";
import Footer from "../../../components/Footer";
import useAdmission from "../../../hooks/useAdmission";
import api from "../../../api/api";
import useProspective from "../../../hooks/useProspective";
import useProspectiveInfo from "../../../hooks/useProspectiveInfo";

function ApprovedDashboard() {
  const user = getCurrentUser();
  const [passport, setPassport] = useState();
  const [details, setDetails] = useState([]);
  const [courseDetails, setCourseDetails] = useState()
  const [admissionFee, setAdmissionFee] = useState();
  const [resultFee, setResultFee] = useState();
  // const [stage, setStage] = useState();

  const [applicationInvoice, setApplicationInvoice] = useState();
  const [resultInvoice, setResultInvoice] = useState();

  const location = useLocation();
  const navigate = useNavigate();

  const { data: dashboard } = useProspective();
  const {staging:stage, status} = useProspectiveInfo()

  const onImageError = (e) => {
    e.target.src = avatar;
  };

  const getDetails = async () => {
    try {
      const { data } = await GetProspectiveDetails(user.admission_id);
      console.log(data, "dataaaa");
      setPassport(data.photo);
      // setStage(data.stage);
      setDetails(data);
      setCourseDetails(data.admin_response[0].approved_program)
    } catch (error) {}
  };
  const fetchPaymentStatus = async () => {
    try {
      const { data } = await GetPaymentStatus(user.admission_id);
      console.log(data, "statuss");
      console.log(data.application_fee, "statusssass");
      setAdmissionFee(data.application_fee);
      console.log(data.result_checking_fee, "checking fee");
      setResultFee(data.result_checking_fee);
    } catch (error) {}
  };

  const getInvoices = async () => {
    try {
      const { data } = await GetPaymentInvoice(user.admission_id);
      setApplicationInvoice(data?.application_fee);
      setResultInvoice(data?.result_checking_fee);
      console.log("Invoice", data);
    } catch (error) {}
  };

  useEffect(() => {
    getDetails();
    // fetchPaymentStatus();
    // getInvoices();
  }, []);

  return (
    <div class="page-wrapper">
      {dashboard && 
      <div class="page-content-tab">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <div class="page-title-box">
                <div class="float-right">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item active">Dashboard</li>
                  </ol>
                </div>
                <h4 class="page-title">Dashboard</h4>
              </div>
            </div>
          </div>
          <div class="alert dspg-dark" role="alert">
            Welcome back, <strong>{user?.username}</strong>
          </div>
          <div class="alert dspg-success" role="alert">
            <h4 class="alert-heading">Congratulations!</h4>
            <p>You have been Offered Provisional Admission. <a href="/admission/admission-letter" class="alert-link">Click Here</a> to Print Admission Letter.</p>
          </div>
          {stage === 'submitted' &&
          <div className={`alert ${((stage === 'submitted') && ((status === 'pending')))? "dspg-warning" : ((stage === 'submitted') && ((status === 'approved'))) ? "dspg-success" :"dspg-danger"}`} role="alert">
          
          {(stage === "submitted") && (status === "pending") ?
            "Awaiting Clearance"
            : (stage === "submitted") && (status === "approved")
                ? "Your Clearance exercise has been approved by administration. Login to the Student Portal"
                : ((stage === "submitted") && (status === "rejected"))
                    ? "Clearance rejected. Please check your mail for details."
                    : ""}
        </div>
          }

          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="met-profile">
                    <div class="row">
                      <div class="col-lg-4 align-self-center mb-3 mb-lg-0">
                        <div class="met-profile-main">
                          <div class="met-profile-main-pic">
                            <img
                              src={passport}
                              onError={onImageError}
                              alt="Passport"
                              className="rounded-circle"
                              style={{ height: "100px", objectFit: "contain" }}
                            />
                          </div>
                          <div class="met-profile_user-detail">
                            <h3 class="">
                              {details
                                ? details?.surname + " " + details?.othername
                                : ""}
                            </h3>
                            <p class="mb-0">Applicant</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 ml-auto">
                        <ul class="list-unstyled">
                          <li class="">
                            <i class="dripicons-phone mr-2 text-info font-18"></i>{" "}
                            <b> phone </b> : {details ? details?.phone : ""}
                          </li>
                          <li class="mt-2">
                            <i class="dripicons-mail text-info font-18 mt-2 mr-2"></i>{" "}
                            <b> Email </b> : {details ? details?.email : ""}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div class="col-lg-12 align-self-center">
              <div className="card">
                <div className="card-body">
                  <div class="table-responsive">
                    <h5 class="mt-0 mb-3 mt-1">
                      <b>Candidate Details</b>
                    </h5>
                    <hr />
                  <table className="table mb-0 table-centered">
                    <tbody>
                        <tr>
                          <td>Application Number</td>
                          <td>{details?.username}</td>
                        </tr>
                        <tr>
                          <td>Course of Study</td>
                          <td>{courseDetails?.department}</td>
                        </tr>
                        <tr>
                          <td>Session</td>
                          <td>{details?.entrySession}</td>
                        </tr>
                        <tr>
                          <td>School</td>
                          <td>{courseDetails?.faculty_name}</td>
                        </tr>
                        <tr>
                          <td>Programme</td>
                          <td>{details?.programmeType}</td>
                        </tr>
                        <tr>
                          <td>Entry Mode</td>
                          <td>{details?.entryMode}</td>
                        </tr>
                        <tr>
                          <td>Study Mode</td>
                          <td>{details?.studyMode}</td>
                        </tr>
                    </tbody>
                  </table>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      } 
    </div>
  );
}

export default ApprovedDashboard;
