import { useState } from "react";
import api from "../../../api/api";
import { getCurrentUser } from "../../../components/services/auth/authService";
import { toast } from "react-toastify";

const GuardianTab = () => {
  const [loading, setLoading] = useState(false);

  const [guardianInfo, setGuardianInfo] = useState({
    email: "",
    name: "",
    address: "",
    phone: "",
  });
  const user = getCurrentUser();

  const handleChange = (e) => {
    setGuardianInfo((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const { name, email, address, phone } = guardianInfo;

  const updateGuardian = async (e) => {
    e.preventDefault();
    try {
        setLoading(true)
      let res = await api.patch(
        `/admissions/applicant/guardian/update/${user.application_id}/`,
        guardianInfo
      );
      console.log(res);
      toast.success("Guardian data updated");
      setLoading(false)
    } catch (error) {
        console.log(error)
        setLoading(false)
    }
  };
  return (
    <div class="row">
      <div class="col-lg-12 col-xl-9 mx-auto">
        <div class="card">
          <div class="card-body">
            <div class="">
              <form
                class="form-horizontal form-material mb-0"
                onSubmit={updateGuardian}
              >
                <div class="form-group row">
                  <div className="col-md-9">
                    <label>Guardian name</label>
                    <input
                      type="text"
                      placeholder="Guardian name"
                      name="name"
                      class="form-control"
                      onChange={handleChange}
                      value={name}
                      required
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-9">
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Guardian email"
                      class="form-control"
                      onChange={handleChange}
                      value={email}
                      required
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-9">
                    <label>Phone Number</label>
                    <input
                      type="number"
                      name="phone"
                      placeholder="Guardian phone"
                      class="form-control"
                      onChange={handleChange}
                      value={phone}
                      required
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-9">
                    <label>Address</label>
                    <input
                      type="text"
                      name="address"
                      placeholder="Guardian Address"
                      class="form-control"
                      onChange={handleChange}
                      value={address}
                      required
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-9">
                    <button className="btn btn-primary" type="submit" disabled={loading}>
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuardianTab;
